import { ChakraProvider } from "@chakra-ui/react";
import React from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Header from "./onest/learning/components/Header";
import ReactGA from "react-ga4";
import authenticatedRoutes from "./routes/authenticatedRoutes";
import guestRoutes from "./routes/guestRoutes";
const Profile = React.lazy(() => import("./view/ProfilePage/ProfilePage"));
const LandingPage = React.lazy(() => import("./onest/LandingPage"));
const List = React.lazy(() => import("./onest/List"));
const View = React.lazy(() => import("./onest/View"));
const AutomatedForm = React.lazy(() => import("./onest/AutomatedForm"));
const MediaPage = React.lazy(() => import("./onest/content/MediaPage"));
const UserDetailsForm = React.lazy(() =>
  import("./onest/content/UserDetailsForm")
);
const Header = React.lazy(() => import("./onest/components/Header"));
const Footer = React.lazy(() => import("./onest/components/Footer"));
const Login = React.lazy(() => import("./view/Authentication/Login"));
const MyConsumption = React.lazy(() =>
  import("./view/MyConsumption/MyConsumption")
);
const MyConsumptionList = React.lazy(() => import("./onest/MyConsumptionList"));

const App = (props) => {
  const [routes, setRoutes] = React.useState();
  // const [token, setToken] = React.useState(false);
  let token = false;
  token = localStorage.getItem("token") ? true : false;
  React.useEffect(() => {
    const fetchroutes = () => {
      if (token) {
        setRoutes(authenticatedRoutes);
      } else {
        setRoutes(guestRoutes);
      }
    };
    fetchroutes();
  }, [token]);
  if (process.env.REACT_APP_GA_KEY) {
    ReactGA.initialize(process.env.REACT_APP_GA_KEY);
  }
  return (
    // <React.Suspense fallback={"loding..."}>
    <ChakraProvider>
      <Router>
        <Header />
        {token ? (
          <Routes>
            <Route path="/onest" element={<LandingPage />} />
            <Route path="/my-consumption" element={<MyConsumption />} />
            <Route
              path="/my-consumption/:title"
              element={<MyConsumptionList />}
            />
            <Route path="my-consumption/:type/:jobId" element={<View />} />

            <Route path="/:type" element={<List />} />
            <Route path="/:type/:jobId" element={<View />} />
            <Route
              path="/:type/automatedForm/:jobId/:transactionId"
              element={<AutomatedForm />}
            />
            <Route
              path="/:type/confirm/:itemId/:transactionId"
              element={<MediaPage />}
            />
            <Route path="/:type/form" element={<UserDetailsForm />} />
            <Route path="*" element={<Profile />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="*" element={<Login />} />
          </Routes>
        )}
        {/* <Routes>
          {routes &&
            routes?.map((item) => (
              <Route
                key={item?.path}
                path={item?.path}
                element={<item.component {...props} />}
              />
            ))}
        </Routes> */}
        <Footer />
      </Router>
    </ChakraProvider>
    // </React.Suspense>
  );
};

export default App;
