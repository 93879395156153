import React from "react";

const Profile = React.lazy(() => import("../view/ProfilePage/ProfilePage"));
const LandingPage = React.lazy(() => import("../onest/LandingPage"));
const List = React.lazy(() => import("../onest/List"));
const View = React.lazy(() => import("../onest/View"));
const AutomatedForm = React.lazy(() => import("../onest/AutomatedForm"));
const MediaPage = React.lazy(() => import("../onest/content/MediaPage"));
const UserDetailsForm = React.lazy(() =>
  import("../onest/content/UserDetailsForm")
);

export default function authenticatedRoutes() {
  return [
    { path: "/:type", component: List },
    { path: "/:type/:jobId", component: View },
    {
      path: "/:type/automatedForm/:jobId/:transactionId",
      component: AutomatedForm,
    },
    { path: "/:type/confirm/:itemId/:transactionId", component: MediaPage },
    { path: "/:type/form", component: UserDetailsForm },
    { path: "*", component: Profile },
    // { path: "*", LandingPage },
  ];
}
